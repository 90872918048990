import "./Extensions.css";
import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Typography, Input, message } from "antd";
import * as Yup from "yup";
import {
  PlusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Edit, Trash2 } from "lucide-react";

import bannedExtensionsApi from "../api/bannedExtensions.js";

const validationSchema = Yup.object({
  name: Yup.string().required().label("Name"),
  id: Yup.string()
    .length(32, "Invalid Extension ID")
    .required()
    .label("Extension ID"),
});

function Extensions() {
  const [bannedExtensions, setBannedExtensions] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [targetedExtension, setTargetedExtension] = useState({});
  const [modalOperation, setModalOperation] = useState(null);
  const [touched, setTouched] = useState(false);

  const [modal, contextHolder] = Modal.useModal();

  const handleOnChange = (key) => (e) => {
    setTouched(true);
    setTargetedExtension({ ...targetedExtension, [key]: e.target.value });
  };

  const handleOnAdd = async () => {
    try {
      const data = await validationSchema.validate(targetedExtension);
      if (
        bannedExtensions.find(
          (ext) => ext.id == data.id || ext.name == data.name
        )
      )
        return message.error("The extension is already exist !");
      const response = await bannedExtensionsApi.add(data);
      setBannedExtensions((prevState) => [
        ...prevState,
        response.bannedExtension,
      ]);
      handleCloseModal();
    } catch (error) {
      message.error(error?.response?.data?.error || error.message);
    }
    setTouched(false);
  };

  const handleOnEdit = async () => {
    try {
      const data = await validationSchema.validate(targetedExtension);
      const { key, _id, ...dataToUpdate } = data;
      const response = await bannedExtensionsApi.update(_id, dataToUpdate);
      if (!response.success) throw new Error("Failed to update extension");
      let newBannedExtensions = [...bannedExtensions];
      let index = newBannedExtensions.findIndex(
        (extension) => extension._id == _id
      );
      if (index == -1) return;
      newBannedExtensions[index] = targetedExtension;
      setBannedExtensions(newBannedExtensions);
      message.success("The extension has been updated successfully");
      handleCloseModal();
    } catch (error) {
      message.error(error?.response?.data?.error || error.message);
    }
    setTouched(false);
  };

  const handleOnClickEdit = (item) => () => {
    setTargetedExtension(item);
    setModalOperation("edit");
    setEditModal(true);
  };

  const handleCloseModal = () => {
    setModalOperation(null);
    setEditModal(false);
    setTouched(false);
  };

  const handleOnDelete = (deletedItem) => async () => {
    const confirm = await modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "Are you sure you want to delete this Extension ?",
      okText: "Delete",
      cancelText: "Cancel",
    });
    if (!confirm) return null;

    try {
      const response = await bannedExtensionsApi.remove(deletedItem._id);
      console.log("delete response: ", response);
      let newBannedExtensions = [...bannedExtensions];
      newBannedExtensions = newBannedExtensions.filter(
        (bannedExtension) => bannedExtension._id !== deletedItem._id
      );

      setBannedExtensions(newBannedExtensions);
    } catch (error) {
      message.error(error?.response?.data?.error || error.message);
    }
  };

  const loadExtensions = async () => {
    try {
      const response = await bannedExtensionsApi.get();
      if (!response.success) throw new Error("unable to fetch data");
      setBannedExtensions(response.bannedExtensions);
    } catch (error) {
      message.error(error?.response?.data?.error || error.message);
    }
  };

  useEffect(() => {
    loadExtensions();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 140,
      fixed: "left",
    },
    {
      title: "Extension ID",
      dataIndex: "id",
      width: "200%",
      width: 400,
    },
    {
      title: "Edit",
      width: 80,
      align: "center",
      render: (...args) => (
        <Edit
          onClick={handleOnClickEdit(...args)}
          style={{ color: "#1677FF", fontSize: 20, cursor: "pointer" }}
        />
      ),
    },
    {
      title: "Delete",
      width: 80,
      align: "center",
      render: (...args) => (
        <Trash2
          onClick={handleOnDelete(...args)}
          style={{ color: "#FF4D4F", fontSize: 20, cursor: "pointer" }}
        />
      ),
    },
  ];
  return (
    <>
      <div className="page banned_extension-page">
        <div className="container">
          <Button
            type="primary"
            danger
            style={{ marginBottom: 10, marginRight: 10, float: "right" }}
            onClick={() => {
              setModalOperation("add");
              setEditModal(true);
              setTargetedExtension({});
            }}
          >
            <PlusCircleOutlined />
            Add
          </Button>
          <Table
            dataSource={bannedExtensions.map((e) => {
              e["key"] = e["_id"];
              return e;
            })}
            columns={columns}
            scroll={{ x: "100%", y: window.innerHeight - (62 + 55 + 66 + 10) }}
            pagination={false}
            size="large"
          />
        </div>
      </div>
      <Modal
        title={modalOperation == "edit" ? "Edit" : "Create New"}
        open={editModal}
        okText={modalOperation == "edit" ? "Save" : "Add"}
        onCancel={handleCloseModal}
        onOk={modalOperation == "edit" ? handleOnEdit : handleOnAdd}
        okButtonProps={{
          disabled: !touched,
        }}
      >
        <Typography.Text>Name</Typography.Text>
        <Input
          placeholder="Cookie Editor"
          value={targetedExtension?.name}
          onChange={handleOnChange("name")}
        />
        <Typography.Text>Extension ID</Typography.Text>
        <Input
          placeholder="fngmhnnpilhplaeedifhccceomclgfbg"
          value={targetedExtension?.id}
          onChange={handleOnChange("id")}
        />
      </Modal>
      {contextHolder}
    </>
  );
}

export default Extensions;
