import "./index.css";

import React from "react";

export default function AppInputTextField({
  label,
  width,
  status,
  error,
  rightIcon,
  ...rest
}) {
  const calc_status = status ? status : "primary";

  return (
    <div className="text_input_container" style={{ width: width }}>
      <label
        className={`label label-status-${calc_status} ${
          error ? "label-error" : ""
        }`}
      >
        {label}
      </label>
      <div
        className={`input input-status-${calc_status} ${
          error ? "input-error" : ""
        }`}
      >
        <input {...rest} />
        {rightIcon}
      </div>
    </div>
  );
}
