import client from "./client";

const endpoint = "/admin/user";

const get = async (id = "") => {
  try {
    const response = await client.get(`${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

const update = async (id = "", payload) => {
  try {
    const response = await client.put(`${endpoint}/${id}`, payload);
    return response.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

const remove = async (id = "") => {
  try {
    const response = await client.delete(`${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

const ban = async ({ userId, isBanned }) => {
  try {
    const response = await client.post(endpoint + "/ban", {
      userId,
      isBanned,
    });
    return response.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export default {
  get,
  update,
  remove,
  ban,
};
