import client from "./client";

const endpoint = "/admin/footer";

const get = async (id = "") => {
  try {
    const response = await client.get(`${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

const add = async (payload) => await client.post(`${endpoint}`, payload);

const edit = async (id = "", payload) => {
  try {
    const response = await client.put(`${endpoint}/${id}`, payload);
    return response.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

const remove = async (id = "") => {
  try {
    const response = await client.delete(`${endpoint}/${id}`);
    return response.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export default {
  get,
  add,
  edit,
  remove,
};
