import "./index.css";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Modal, Typography, Input, message, InputNumber } from "antd";

import adminsApi from "../../api/admins";

export default function NotificationModal({
  users,
  subscriptions,
  open,
  setOpen,
}) {
  const [usersToSelect, setUsersToSelect] = useState([]);
  const [selectedUsers, setSelectedUser] = useState([]);
  const [usersIds, setUsersIds] = useState([]);
  const [notification, setNotification] = useState({
    delay: 2,
    iterations: 1,
    message: "",
    url: "",
  });

  const handleSubscriptionFilter = (subscriptions) => {
    const subscriptionsIds = subscriptions.map(
      (subscription) => subscription.value
    );

    const newUsersToSelect = users.filter(
      (user) =>
        user.mySubscriptions.some((mySubscription) =>
          subscriptionsIds.includes(mySubscription._id)
        ) ||
        (user.mySubscriptions.length == 0 &&
          subscriptionsIds.includes("UNSBSCRIBED")) ||
        (subscriptionsIds.includes("ALL_SUBSCRIPTIONS") &&
          user.mySubscriptions.length > 0) ||
        subscriptionsIds.includes("ALL_USERS")
    );

    setUsersToSelect(newUsersToSelect);
  };

  const handleNotificationChange = (key) => (event) => {
    const value = typeof event == "number" ? event : event.target.value;
    setNotification((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleClose = () => {
    setOpen(false);
    setUsersIds([]);
    setSelectedUser([]);
  };

  const onSubmit = async () => {
    if (
      selectedUsers.length == 0 ||
      !notification.message ||
      !notification.delay ||
      !notification.iterations
    )
      return message.error("All Fields Are Required!");

    const payload = { usersIds, ...notification };

    try {
      const response = await adminsApi.sendNotification(payload);
      if (!response.success) return message.error(response.error);
      message.success("Successfully sent!");
    } catch (error) {
      message.error(error.response.error || error.message);
    }
    setOpen(false);
    setNotification({
      delay: 2,
      iterations: 1,
      message: "",
      url: "",
    });
    handleClose();
  };

  useEffect(() => {
    // set the selected users
    const newSelectedUsers = usersToSelect.map((user) => ({
      value: user["_id"],
      label: user["name"],
    }));
    setSelectedUser(newSelectedUsers);
  }, [usersToSelect]);

  useEffect(() => {
    const newUsersIds = selectedUsers.map((user) => user.value);
    setUsersIds(newUsersIds);
  }, [selectedUsers]);

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      onOk={onSubmit}
      okText="Send"
      destroyOnClose
    >
      <label style={{ marginTop: 30 }}>Notify Users</label>
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <Typography.Text required>Subscription</Typography.Text>
        <Select
          isMulti
          options={[
            {
              value: "ALL_USERS",
              label: "All Users",
            },
            {
              value: "UNSBSCRIBED",
              label: "UNSBSCRIBED",
            },
            {
              value: "ALL_SUBSCRIPTIONS",
              label: "All Subscriptions",
            },
            ...subscriptions?.map((subscription) => ({
              value: subscription["_id"],
              label: subscription["name"],
            })),
          ]}
          onChange={handleSubscriptionFilter}
        />

        <Typography.Text required>Users</Typography.Text>
        <Select
          options={usersToSelect?.map((user) => ({
            value: user["_id"],
            label: user["name"],
          }))}
          value={selectedUsers}
          onChange={setSelectedUser}
          closeMenuOnSelect={false}
          isMulti
          style={{ maxHeight: 20, overflow: "hidden" }}
        ></Select>

        <Typography.Text>
          URL{" "}
          <span style={{ fontStyle: "italic", fontSize: 12, color: "grey" }}>
            ( Optional )
          </span>
        </Typography.Text>

        <Input
          placeholder="https://www.example.com"
          type="text"
          onChange={handleNotificationChange("url")}
        />

        <Typography.Text required>Delay</Typography.Text>
        <InputNumber
          onChange={handleNotificationChange("delay")}
          value={notification.delay}
          addonAfter="Sec"
          defaultValue={2}
          min={2}
        />

        <Typography.Text required>Iterations</Typography.Text>
        <InputNumber
          onChange={handleNotificationChange("iterations")}
          value={notification.iterations}
          addonAfter="Times"
          defaultValue={1}
          min={1}
          max={5}
        />

        <Typography.Text required>Message</Typography.Text>
        <Input.TextArea
          placeholder="Message"
          onChange={handleNotificationChange("message")}
          value={notification.message}
          autoSize={{
            minRows: 5,
            maxRows: 5,
          }}
        />
      </div>
    </Modal>
  );
}
