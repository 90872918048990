import "./Links.css";
import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Typography, Input, message } from "antd";
import * as Yup from "yup";
import {
  PlusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Edit, Trash2 } from "lucide-react";

import linksApi from "../api/links.js";

const validationSchema = Yup.object({
  name: Yup.string().required().label("Name"),
  url: Yup.string()
    .matches(
      /\b((http|https):\/\/)?(([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?)/,
      "Invalid URL"
    )
    .required()
    .label("Url"),
});

function Extensions() {
  const [links, setLinks] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [targetedLink, setTargetedLink] = useState({});
  const [modalOperation, setModalOperation] = useState(null);
  const [touched, setTouched] = useState(false);

  const [modal, contextHolder] = Modal.useModal();

  const handleOnChange = (key) => (e) => {
    setTouched(true);
    setTargetedLink({ ...targetedLink, [key]: e.target.value });
  };

  const handleOnAdd = async () => {
    try {
      const data = await validationSchema.validate(targetedLink);
      if (links.find((link) => link.url == data.url || link.name == data.name))
        return message.error("The link is already exist !");
      const response = await linksApi.add(data);
      setLinks((prevState) => [...prevState, response.data.links]);
      handleCloseModal();
      setTouched(false);
    } catch (error) {
      message.error(error?.response?.data?.error || error.message);
    }
  };

  const handleOnEdit = async () => {
    try {
      const data = await validationSchema.validate(targetedLink);
      const { key, _id, ...dataToUpdate } = data;
      const response = await linksApi.edit(_id, dataToUpdate);
      if (!response.success) throw new Error("Failed to update link");
      let newLinks = [...links];
      let index = newLinks.findIndex((link) => link._id == _id);
      if (index == -1) return;
      newLinks[index] = targetedLink;
      setLinks(newLinks);
      message.success("The link has been updated successfully");
      handleCloseModal();
    } catch (error) {
      message.error(error?.response?.data?.error || error.message);
    }
    setTouched(false);
  };

  const handleOnClickEdit = (item) => () => {
    setTargetedLink(item);
    setModalOperation("edit");
    setEditModal(true);
  };

  const handleCloseModal = () => {
    setModalOperation(null);
    setEditModal(false);
    setTouched(false);
  };

  const handleOnDelete = (deletedItem) => async () => {
    const confirm = await modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "Are you sure you want to delete this Link ?",
      okText: "Delete",
      cancelText: "Cancel",
    });
    if (!confirm) return null;

    try {
      const response = await linksApi.remove(deletedItem._id);
      console.log("delete response: ", response);
      let newLinks = [...links];
      newLinks = newLinks.filter((link) => link._id !== deletedItem._id);

      setLinks(newLinks);
    } catch (error) {
      message.error(error?.response?.data?.error || error.message);
    }
  };

  const loadLinks = async () => {
    try {
      const response = await linksApi.get();
      if (!response.success) throw new Error("unable to fetch data");
      setLinks(response.links);
    } catch (error) {
      message.error(error?.response?.data?.error || error.message);
    }
  };

  useEffect(() => {
    loadLinks();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 140,
      fixed: "left",
    },
    {
      title: "URL",
      dataIndex: "url",
      width: "200%",
      width: 400,
    },
    {
      title: "Edit",
      width: 80,
      align: "center",
      render: (...args) => (
        <Edit
          onClick={handleOnClickEdit(...args)}
          style={{ color: "#1677FF", fontSize: 20, cursor: "pointer" }}
        />
      ),
    },
    {
      title: "Delete",
      width: 80,
      align: "center",
      render: (...args) => (
        <Trash2
          onClick={handleOnDelete(...args)}
          style={{ color: "#FF4D4F", fontSize: 20, cursor: "pointer" }}
        />
      ),
    },
  ];
  return (
    <>
      <div className="page banned_extension-page">
        <div className="container">
          <Button
            type="primary"
            danger
            style={{ marginBottom: 10, marginRight: 10, float: "right" }}
            onClick={() => {
              setModalOperation("add");
              setEditModal(true);
              setTargetedLink({});
            }}
          >
            <PlusCircleOutlined />
            Add
          </Button>
          <Table
            dataSource={links}
            columns={columns}
            scroll={{ x: "100%", y: window.innerHeight - (62 + 55 + 66 + 10) }}
            pagination={false}
            size="large"
          />
        </div>
      </div>
      <Modal
        title={modalOperation == "edit" ? "Edit" : "Create New"}
        open={editModal}
        okText={modalOperation == "edit" ? "Save" : "Add"}
        onCancel={handleCloseModal}
        onOk={modalOperation == "edit" ? handleOnEdit : handleOnAdd}
        okButtonProps={{
          disabled: !touched,
        }}
      >
        <Typography.Text>Name</Typography.Text>
        <Input
          placeholder="Google"
          value={targetedLink?.name}
          onChange={handleOnChange("name")}
        />
        <Typography.Text>URL</Typography.Text>
        <Input
          placeholder="https://www.google.com"
          value={targetedLink?.url}
          onChange={handleOnChange("url")}
        />
      </Modal>
      {contextHolder}
    </>
  );
}

export default Extensions;
