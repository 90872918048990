import client from "./client";

const endpoint = "/admin";

const singin = async ({ email, password }) => {
  try {
    const response = await client.post(`${endpoint}/auth/signin`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    return (
      error.response.data || {
        success: false,
        error: error.message,
      }
    );
  }
};

const sendNotification = async (data) => {
  try {
    const response = await client.post(`${endpoint}/notification`, data);
    return response.data;
  } catch (error) {
    return (
      error.response.data || {
        success: false,
        error: error.message,
      }
    );
  }
};

const manageUsers = async (data) => {
  try {
    const response = await client.post(`${endpoint}/manageUsers`, data);
    return response.data;
  } catch (error) {
    return (
      error.response.data || {
        success: false,
        error: error.message,
      }
    );
  }
};

export default {
  singin,
  sendNotification,
  manageUsers,
};
