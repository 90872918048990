import "react-multi-email/dist/style.css";
import "./Users.css";
import React, { useEffect, useState } from "react";
import {
  Card,
  Statistic,
  Table,
  Avatar,
  Divider,
  Button,
  Badge,
  Tag,
  Modal,
  message,
  Typography,
  Switch,
  Tooltip,
  Input,
} from "antd";
import Select from "react-select";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { LockOpen, Lock, Trash2, Edit } from "lucide-react";
import { isMobile } from "react-device-detect";
import { ReactMultiEmail } from "react-multi-email";

import subscriptionsApi from "../api/subscriptions";
import adminApi from "../api/admins";
import usersApi from "../api/users";
import NotificationModal from "../components/NotificationModal";
import { set } from "mongoose";

function Users({ onlineUsers, socket, bannedUserLists }) {
  const [users, setUsers] = useState([]);
  const [filterdUsers, setFilterdUsers] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [loading, setLoading] = useState(false);

  const [tragetUser, setTargetUser] = useState(null);
  const [notifyModal, setNotifyModal] = useState(false);

  const [manageUsersModal, setManageUsersModal] = useState(false);
  const [manageUsersAction, setManageUsersAction] = useState(null);
  const [manageUsersSubscription, setManageUsersSubscription] = useState(null);

  const [onlineUsersCount, setOnlineUsersCount] = useState(0);
  const [bannedUsersCount, setBannedUsersCount] = useState(0);

  const [mobileEmailSearch, setMobileEmailSearch] = useState("");
  const [mobileTargetUserIndx, setMobileTargetUserIndx] = useState(null);

  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState([]);

  const [modal, contextHolder] = Modal.useModal();

  const handleDelete = (user) => async () => {
    const confirm = await modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "Are you sure you want to delete this user ?",
      okText: "Delete",
      cancelText: "Cancel",
    });
    if (!confirm) return null;
    const response = await usersApi.remove(user._id);
    if (!response.success)
      return message.error(`unable to [delete] ${user.name}`);
    let newData = users.filter((u) => u._id != user._id);
    setUsers([...newData]);
    message.success(`${user.name} has been successfully [deleted]`);
  };

  const handleEdit = (user) => () => {
    setTargetUser(user);
    setModalVisibility(true);
  };

  const handleBanning = (user) => async () => {
    const isBanned = !user.banned;
    if (isBanned) {
      const confirm = await modal.confirm({
        title: "Confirm",
        icon: <ExclamationCircleOutlined />,
        content: "Are you sure you want to ban this user ?",
        okText: "Ban",
        cancelText: "Cancel",
      });
      if (!confirm) return null;
    }
    const response = await usersApi.ban({
      userId: user._id,
      isBanned: isBanned,
    });
    if (!response.success)
      return message.error(
        `unable to [${isBanned ? "unban" : "ban"}] ${user.name}`
      );
    const newusers = [...users];
    const indx = newusers.findIndex((_user) => _user._id == user._id);
    newusers[indx].banned = isBanned;
    if (isBanned) newusers[indx].isOnline = false;
    setUsers(newusers);
    message[isBanned ? "warning" : "success"](
      `${user.name} has been successfully [${isBanned ? "banned" : "unbanned"}]`
    );
  };

  const handleSubscriptionsChange = (_newSubscriptions) => {
    const newSubscriptions = _newSubscriptions.map(({ value, label }) => ({
      _id: value,
      name: label,
    }));

    setTargetUser({ ...tragetUser, mySubscriptions: newSubscriptions });
  };

  const handleSave = async () => {
    if (tragetUser.mySubscriptions?.length < subscriptions?.length) {
      const [user] = users.filter((user) => user._id == tragetUser._id);
      const newSubsIds = tragetUser.mySubscriptions.map(
        (subscription) => subscription._id
      );
      const oldSubsIds = user.mySubscriptions.map(
        (subscription) => subscription._id
      );

      const deleted_subscriptions_ids = oldSubsIds.filter((oldId) => {
        return !newSubsIds.includes(oldId);
      });

      const deleted_subscriptions = subscriptions
        .filter((subscription) => {
          return deleted_subscriptions_ids.includes(subscription._id);
        })
        .map((s) => {
          delete s.cookies;
          delete s.__v;
          return s;
        });
    }
    const response = await usersApi.update(tragetUser._id, {
      mySubscriptions: tragetUser.mySubscriptions,
    });
    if (!response.success)
      return message.error("unable to complete the operation");
    const newusers = [...users];
    const indx = newusers.findIndex((user) => user._id == tragetUser._id);
    newusers[indx] = tragetUser;
    setUsers(newusers);
    setModalVisibility(false);
    message.success("successfully updated");
  };

  const loadUsers = async () => {
    const response = await usersApi.get();
    if (!response.success) return message.error(response.error);
    let newUsers = response.users.map((user, index) => {
      user["key"] = index;
      return user;
    });
    newUsers = newUsers.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      const isValidDateA = !isNaN(dateA);
      const isValidDateB = !isNaN(dateB);

      if (isValidDateA && isValidDateB) {
        return dateB - dateA; // Sort descending
      }
      if (isValidDateA) {
        return -1; // valid dates come first
      }
      if (isValidDateB) {
        return 1; // valid dates come first
      }
      return 0; // both are invalid dates
    });
    setUsers(newUsers);
  };

  const loadSubscriptions = async () => {
    const response = await subscriptionsApi.get();
    if (!response.success) return message.error(response.error);
    setSubscriptions(response.subscription);
  };

  const handleManageUsersSubmit = async () => {
    let allUsers = [...users];
    allUsers = allUsers.map((user) => user.email);
    const emailsToEdit = emails.filter((email) => allUsers.includes(email));
    const response = await adminApi.manageUsers({
      users: emailsToEdit,
      action: manageUsersAction.value,
      subscription: manageUsersSubscription?.value,
    });
    if (response.ok) return message.error("Unable to apply those updates");
    setUsers(response.updatedUsers);

    message.success("updates successfuly applied");
    setManageUsersModal(false);
    setEmails([]);
  };

  const handleSearchUser = () => {
    const userIndx = users.findIndex(
      (user) => user.email == mobileEmailSearch.trim().toLocaleLowerCase()
    );
    if (userIndx == -1) {
      setMobileTargetUserIndx(null);
      return message.error("No User Found by This Email !");
    }
    setMobileTargetUserIndx(userIndx);
  };

  useEffect(() => {
    try {
      setLoading(true);
      loadUsers();
      loadSubscriptions().finally(() => setLoading(false));
    } catch (error) {}
  }, []);

  useEffect(() => {
    const newOnlineUsersCount = users.filter((user) => user.sid).length;
    const newBannedUsersCount = users.filter((user) => user.banned).length;
    setOnlineUsersCount(newOnlineUsersCount);
    setBannedUsersCount(newBannedUsersCount);
  }, [users, onlineUsers]);

  useEffect(() => {
    const newUsers = users.map((user) => {
      if (bannedUserLists.includes(user._id)) {
        user["banned"] = true;
      }
      return user;
    });
    setUsers(newUsers);
  }, [bannedUserLists]);

  useEffect(() => {
    setFilterdUsers(users.length);
  }, [users]);

  useEffect(() => {
    if (socket) {
      const online_user_handler = (user) => {
        setUsers((prevUsers) => {
          const index = prevUsers.findIndex((u) => u._id === user._id);
          if (index !== -1) {
            prevUsers[index].sid = true;
            return [...prevUsers];
          }
          return prevUsers;
        });
      };

      const offline_user_handler = (user) => {
        setUsers((prevUsers) => {
          const index = prevUsers.findIndex((u) => u._id === user._id);
          if (index !== -1) {
            prevUsers[index].sid = false;
            return [...prevUsers];
          }
          return prevUsers;
        });
      };

      const login_handler = (user) => {
        setUsers((prevUsers) => {
          const index = prevUsers.findIndex((u) => u._id === user._id);
          if (index === -1) return prevUsers;
          prevUsers[index].isOnline = user.isOnline;
          return [...prevUsers];
        });
      };

      const ban_handler = (user) => {
        setUsers((prevUsers) => {
          const index = prevUsers.findIndex((u) => u._id === user._id);
          if (index === -1) return prevUsers;
          prevUsers[index].banned = user.banned;
          return [...prevUsers];
        });
      };

      socket.on("online_user", online_user_handler);
      socket.on("offline_user", offline_user_handler);
      socket.on("Login", login_handler);
      socket.on("banned_user", ban_handler);
      //banned_user
      return () => {
        socket?.off("online_user", online_user_handler);
        socket?.off("offline_user", offline_user_handler);
        socket?.off("Login", login_handler);
        socket?.on("banned_user", ban_handler);
      };
    }
  }, [socket]);

  const handleOnlineUsers = (users) => {
    return users.map((user) => {
      user["status"] = false;
      user["devices"] = 0;

      onlineUsers.forEach((onlineUser) => {
        if (user["_id"] == onlineUser["_id"]) {
          const devices = onlineUsers.filter(
            (onlineUser) => onlineUser["_id"] == user["_id"]
          );
          user["status"] = true;
          user["devices"] = devices.length;
          user["banned"] = user.banned || onlineUser["banned"];
        }
      });

      return user;
    });
  };

  const columns = [
    {
      title: "Name",
      width: 180,
      fixed: "left",
      filterSearch: true,
      filters: users.map((user) => ({
        text: user.name,
        value: user.name,
      })),
      onFilter: (value, record) => record.name.includes(value),
      render: ({ name, sid, banned, isOnline, color }) => {
        return (
          <>
            <Badge
              dot
              color={banned ? "#FFCC00" : sid && isOnline ? "green" : "red"}
            >
              <div
                style={{
                  padding: 3,
                  borderRadius: "50%",
                  boxShadow:
                    "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
                }}
              >
                <Avatar
                  style={{
                    backgroundColor: color || "grey",
                    verticalAlign: "middle",
                  }}
                  size="default"
                  gap={4}
                >
                  {name[0].toUpperCase()}
                </Avatar>
              </div>
            </Badge>

            <span style={{ marginLeft: 10 }}>
              {name[0].toUpperCase() + name.slice(1, name.length)}
            </span>
          </>
        );
      },
    },
    {
      title: "Logged-In",
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isOnline == value,
      render: ({ isOnline }) => (
        <Tag color={isOnline ? "blue" : "red"}>
          {isOnline ? (
            <>
              <Lock size={12} style={{ marginRight: 5 }} />
              {"Yes"}
            </>
          ) : (
            <>
              <LockOpen size={12} style={{ marginRight: 5 }} />
              {"No"}
            </>
          )}
        </Tag>
      ),
      width: 110,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 200,
      filterSearch: true,
      filters: users.map((user) => ({
        text: user.email,
        value: user.email,
      })),
      onFilter: (value, record) => record.email.includes(value),
    },
    {
      title: "Subscriptions",
      dataIndex: "mySubscriptions",
      width: 300,
      filterSearch: true,
      filters: subscriptions.map((subscription) => ({
        text: subscription.name,
        value: subscription._id,
      })),
      onFilter: (value, record) => {
        return record.mySubscriptions.some(
          (mySubscription) => mySubscription._id === value
        );
      },
      render: (subscriptions) => (
        <div>
          {subscriptions && subscriptions.length < 1 ? (
            <Tag
              color={"grey"}
              key={"unsubscribed"}
              style={{ marginBottom: 5 }}
            >
              UNSBSCRIBED
            </Tag>
          ) : (
            subscriptions &&
            subscriptions.map((subscription) => (
              <Tag
                color={"green"}
                key={subscription["_id"]}
                style={{ marginBottom: 5 }}
              >
                {subscription["name"]?.toUpperCase()}
              </Tag>
            ))
          )}
        </div>
      ),
    },
    {
      title: "Actions",
      width: 170,
      render: (item) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            style={{ padding: 1, marginRight: 5 }}
            type="link"
            danger
            onClick={handleDelete(item)}
          >
            <Trash2 />
          </Button>
          <Button
            style={{ padding: 1, marginRight: 5 }}
            type="link"
            onClick={handleEdit(item)}
          >
            <Edit />
          </Button>
          <Tooltip title={item.banned ? "UNBAN" : "BAN"}>
            <Switch
              checked={item.banned}
              onChange={handleBanning(item)}
              checkedChildren="Uban"
              unCheckedChildren="Ban"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page users_page">
        {isMobile ? (
          <div style={{ padding: 10 }}>
            <div style={{ margin: "40px 0 20px 0  ", display: "flex" }}>
              <Input
                placeholder="Email"
                style={{ marginRight: 10, padding: "5px 5px", fontSize: 16 }}
                value={mobileEmailSearch}
                onChange={(e) => setMobileEmailSearch(e.target.value)}
              />
              <Button
                style={{
                  padding: "5px 2.5px",
                  boxSizing: "content-box",
                  width: 70,
                }}
                type="primary"
                onClick={handleSearchUser}
              >
                Search
              </Button>
            </div>
            <div
              style={{
                width: "100%",
                height: "90%",
                overflow: "auto",
              }}
            >
              {users[mobileTargetUserIndx] && (
                <>
                  <Card>
                    <Typography.Text>
                      Name: {users[mobileTargetUserIndx]?.name}
                    </Typography.Text>
                    <Typography.Text>
                      Email: {users[mobileTargetUserIndx]?.email}
                    </Typography.Text>
                    <Typography.Text>
                      Subscriptions:{" "}
                      {users[mobileTargetUserIndx]?.mySubscriptions.length >
                      0 ? (
                        users[mobileTargetUserIndx]?.mySubscriptions.map(
                          (subscription) => (
                            <Tag key={subscription._id} color="green">
                              {subscription.name}
                            </Tag>
                          )
                        )
                      ) : (
                        <Tag
                          color={"grey"}
                          key={"unsubscribed"}
                          style={{ marginBottom: 5 }}
                        >
                          UNSBSCRIBED
                        </Tag>
                      )}
                    </Typography.Text>
                  </Card>
                  <div className="actions">
                    <Button
                      type="link"
                      danger
                      onClick={() => {
                        handleDelete(users[mobileTargetUserIndx])().then(() => {
                          setMobileTargetUserIndx(null);
                        });
                      }}
                    >
                      <Trash2 size={40} />
                    </Button>
                    <Button
                      type="link"
                      onClick={handleEdit(users[mobileTargetUserIndx])}
                    >
                      <Edit size={40} />
                    </Button>
                    <Tooltip
                      title={
                        users[mobileTargetUserIndx]?.banned ? "UNBAN" : "BAN"
                      }
                    >
                      <Switch
                        checked={users[mobileTargetUserIndx]?.banned}
                        onChange={handleBanning(users[mobileTargetUserIndx])}
                        checkedChildren="Uban"
                        unCheckedChildren="Ban"
                      />
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="users_statistic">
              <Card bordered={false}>
                <Statistic
                  title="Total Users"
                  value={users.length}
                  valueStyle={{ color: "#000000aa" }}
                />
              </Card>
              <Card bordered={false}>
                <Statistic
                  title="Filterd Users"
                  value={filterdUsers}
                  valueStyle={{ color: "#000000aa" }}
                />
              </Card>
              <Card bordered={false}>
                <Statistic
                  title="Online Users"
                  value={onlineUsersCount}
                  valueStyle={{ color: "#20d23a" }}
                />
              </Card>
              <Card bordered={false}>
                <Statistic
                  title="Banned Users"
                  value={bannedUsersCount}
                  valueStyle={{ color: "#FFCC00" }}
                />
              </Card>
            </div>
            <Divider />
            <div>
              <Button
                type="dashed"
                danger
                style={{ float: "right", marginRight: 15, marginBottom: 15 }}
                onClick={() => {
                  setNotifyModal(true);
                }}
              >
                Notify Users
              </Button>
              <Button
                type="dashed"
                style={{
                  float: "right",
                  marginRight: 15,
                  marginBottom: 15,
                }}
                onClick={() => {
                  setManageUsersModal(true);
                }}
              >
                Manage Users
              </Button>
            </div>
            <div className="users-table">
              <Table
                columns={columns}
                dataSource={handleOnlineUsers(users)}
                scroll={{ x: 300, y: window.innerHeight - 276.825 }}
                pagination={false}
                loading={loading}
                onChange={(pagination, filters, sorter, extra) => {
                  setFilterdUsers(extra.currentDataSource.length);
                }}
              />
            </div>
          </>
        )}
      </div>
      <Modal
        open={modalVisibility}
        onCancel={() => setModalVisibility(false)}
        onOk={handleSave}
        okText="Save"
      >
        <label style={{ marginTop: 30 }}>Subscriptions</label>
        <Select
          isMulti
          value={
            tragetUser &&
            tragetUser.mySubscriptions.map((subscription) => ({
              value: subscription["_id"],
              label: subscription["name"],
            }))
          }
          options={subscriptions?.map((subscription) => ({
            value: subscription["_id"],
            label: subscription["name"],
          }))}
          onChange={handleSubscriptionsChange}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#1877F2" : "grey",
            }),
          }}
        />
      </Modal>
      <NotificationModal
        users={users}
        subscriptions={subscriptions}
        open={notifyModal}
        setOpen={setNotifyModal}
      />
      <Modal
        title="Manage Users"
        open={manageUsersModal}
        onCancel={() => {
          setManageUsersModal(false);
          setEmails([]);
          setManageUsersAction(null);
          setManageUsersSubscription(null);
        }}
        okText="Apply"
        onOk={handleManageUsersSubmit}
        okButtonProps={{
          disabled:
            emails.length == 0 ||
            !emails ||
            !manageUsersAction ||
            (manageUsersAction.value != "delete" && !manageUsersSubscription),
        }}
        destroyOnClose
      >
        <ReactMultiEmail
          placeholder="Users Emails"
          emails={emails}
          onChange={(_emails) => {
            setEmails(_emails);
          }}
          autoFocus={true}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          getLabel={(email, index, removeEmail) => {
            return (
              <div data-tag key={index}>
                <div data-tag-item>{email}</div>
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        />

        <div
          style={{
            marginTop: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Select
            styles={{
              container: (baseStyles, state) => ({
                ...baseStyles,
                flex: 0.575,
              }),
            }}
            options={[
              { value: "delete", label: "Delete" },
              { value: "add_subscriptions", label: "Add Subscriptions" },
              { value: "remove_subscriptions", label: "Remove Subscriptions" },
            ]}
            onChange={(selected) => {
              if (selected.value == "delete") setManageUsersSubscription(null);
              setManageUsersAction(selected);
            }}
            value={manageUsersAction}
            placeholder="Action"
          />
          {(manageUsersAction?.value == "add_subscriptions" ||
            manageUsersAction?.value == "remove_subscriptions") && (
            <Select
              styles={{
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  flex: 0.4,
                }),
              }}
              options={subscriptions?.map((subscription) => ({
                value: subscription["_id"],
                label: subscription["name"],
              }))}
              placeholder="Subscriptions"
              onChange={(value) => setManageUsersSubscription(value)}
            />
          )}
        </div>
      </Modal>
      {contextHolder}
    </>
  );
}

export default Users;
