import "./App.css";
import { useEffect, useRef, useState } from "react";
import { Menu, Switch, message } from "antd";
import { Users as UsersIcon, List, Puzzle, Link2, LogOut } from "lucide-react";
import { jwtDecode } from "jwt-decode";
import io from "socket.io-client";

import LoginPage from "./pages/LoginPage/";
import Users from "./pages/Users";
import Susbscriptions from "./pages/Susbscriptions";
import Extensions from "./pages/Extensions";
import Links from "./pages/Links";

import config from "./config/";

const items = [
  getItem("Users", "users", <UsersIcon />),
  getItem("Subscriptions", "subscriptions", <List />),
  getItem("Extensions", "extensions", <Puzzle />),
  getItem("Links", "links", <Link2 />),
  getItem("Logout", "logout", <LogOut />),
];

const pages = {
  users: (onlineUsers, socket, bannedUserLists) => (
    <Users
      onlineUsers={onlineUsers}
      socket={socket}
      bannedUserLists={bannedUserLists}
    />
  ),
  subscriptions: () => <Susbscriptions />,
  extensions: () => <Extensions />,
  links: () => <Links />,
};

function App() {
  const [keypath, setKeypath] = useState("users");
  const [socket, setSocket] = useState(null);

  const [admin, setAdmin] = useState(null);

  const [onlineUsers, setOnlineUsers] = useState([]);
  const [bannedUserLists, setBannedUserLists] = useState([]);

  const indicatorRef = useRef(null);

  const menuPressHandler = ({ keyPath }) => {
    if (keyPath != "logout") return setKeypath(keyPath);
    localStorage.removeItem("token");
    window.location.reload();
    socket?.close();
  };

  const mobileMenuHandler = (number) => (event) => {
    const xAxis = number * 66;
    const keyPath = event.target.getAttribute("data-key");
    if (keyPath === "logout") {
      localStorage.removeItem("token");
      window.location.reload();
      return;
    }
    if (!pages[keyPath]) return;
    setKeypath(keyPath);
    indicatorRef.current.style.setProperty("--translateX", `${xAxis}px`);
  };

  const loadAdmin = () => {
    const token = localStorage.getItem("token");
    if (!token) return setAdmin(null);
    try {
      const admin = jwtDecode(token);
      setAdmin(admin);
    } catch (error) {
      message.error("Invalid token specified Realod the page and try again");
      localStorage.removeItem("token");
    }
  };

  useEffect(() => {
    document.title = "Admin - Dashboard";
  });

  useEffect(() => {
    loadAdmin();
  }, []);

  useEffect(() => {
    // Connect to the WebSocket server

    if (admin) {
      const socket = io(config.baseURL + "/admin", {
        transports: ["websocket"],
      });

      setSocket(socket);

      return () => {
        // Clean up the WebSocket connection when the component unmounts
        socket.close();
      };
    }
  }, [admin]);

  return !admin ? (
    <LoginPage setAdmin={setAdmin} />
  ) : (
    <div className="App">
      <div className="panel-container">
        <Menu
          defaultSelectedKeys={keypath}
          mode={"inline"}
          theme={"dark"}
          items={items}
          onClick={menuPressHandler}
          rootClassName="side-bar"
        />
        {pages[keypath] && pages[keypath](onlineUsers, socket, bannedUserLists)}
      </div>
      <div className="mobile-menu">
        <div
          data-key="users"
          className="mobile-menu-item"
          onClick={mobileMenuHandler(0)}
          ref={indicatorRef}
        >
          <UsersIcon className="menu-icon" />
        </div>
        <div
          data-key="subscriptions"
          className="mobile-menu-item "
          onClick={mobileMenuHandler(1)}
        >
          <List className="menu-icon" />
        </div>
        <div
          data-key="extensions"
          className="mobile-menu-item"
          onClick={mobileMenuHandler(2)}
        >
          <Puzzle className="menu-icon" />
        </div>
        <div
          data-key="links"
          className="mobile-menu-item"
          onClick={mobileMenuHandler(3)}
        >
          <Link2 className="menu-icon" />
        </div>

        <div
          data-key="logout"
          className="mobile-menu-item"
          onClick={mobileMenuHandler(4)}
        >
          <LogOut className="menu-icon" />
        </div>
      </div>
    </div>
  );
}
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

export default App;
