import "./index.css";
import React, { useEffect, useState } from "react";

import { Button, message } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { jwtDecode } from "jwt-decode";

import AppInputTextFeild from "../../components/AppInputTextFeild";
import ErrorMessage from "../../components/ErrorMessage";

import adminsApi from "../../api/admins";

const validationSchema = Yup.object({
  email: Yup.string().email().required().label("Email"),
  password: Yup.string().min(8).max(16).required().label("Password"),
});

export default function LoginPage({ setAdmin }) {
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Admin - Log In";
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const response = await adminsApi.singin(values);
    if (!response.success) message.error(response.error);
    localStorage.setItem("token", response.token);
    setAdmin(jwtDecode(response.token));
    setSubmitting(false);
  };

  return (
    <main className="page page_login">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <div className="form">
            <h2 className="header">Admin Panel Login</h2>
            <p className="subheader">Enter your credentials below</p>
            <AppInputTextFeild
              name="email"
              label="Email"
              placeholder="Your Email Here"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              status="secondary"
              error={touched.email && errors.email}
            />
            <ErrorMessage error={touched.email && errors.email} />
            <AppInputTextFeild
              name="password"
              label="Password"
              placeholder="Password"
              type="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              status="secondary"
              error={touched.password && errors.password}
            />
            <ErrorMessage error={touched.password && errors.password} />
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={
                !touched.email ||
                !touched.password ||
                errors.email ||
                errors.password ||
                isSubmitting
              }
            >
              Login
            </Button>
          </div>
        )}
      </Formik>
    </main>
  );
}
